<!--  -->
<template>
  <div class="content">
    <div class="inner">
      <div class="box layout-flex-all">
        <div class="left">
          <div class="project-name layout-flex">Castle会员中心</div>
          <ul class="list">
            <li>
              <i class="iconfont pdxduihao"></i>
              敏捷开发,智能部署
            </li>
            <li>
              <i class="iconfont pdxduihao"></i>接口丰富全面,覆盖各种场景
            </li>
          </ul>
        </div>
        <div class="right" v-if="loginFlag == 'wx'">
          <p class="name">
            <img src="@/assets/img/login_wx.png" alt />
            微信扫码登录
          </p>
          <div
            @mouseenter="enter"
            @mouseleave="leave"
            ref="wxQrcode"
            class="part layout-flex"
            style="transition: all .3s;width:200px;margin:0 auto;overflow:hidden;"
          >
            <div class="form">
              <div id="wxQrcode" style="width: 200px; height: 200px;overflow:hidden"></div>
              <!-- <p class="wx-detail">请使用微信扫描二维码登录</p> -->
            </div>
            <div class="pic" style="opacity: 0;" ref="pic">
              <img src="@/assets/img/wx_ck.png" alt />
            </div>
          </div>
          <p class="agree">
              登录即代表同意
              <a href='https://open.icuapi.com/agress/service'>《华创服务协议》</a>和
              <a href='https://open.icuapi.com/agress/protection'>《隐私保护政策》</a>
            </p>
          <!-- <div class="loginCheck">
            <el-checkbox v-model="checked">下次自动登录</el-checkbox>
          </div>-->
          <div class="top" @click="change">
            <div class="line">
              <p>验证码登录</p>
              <img src="@/assets/img/computed.png" alt />
            </div>
          </div>
        </div>
        <div class="right phone" v-if="loginFlag == 'phone'">
          <div class="welcome">
            <h3>欢迎登录</h3>
            <p>Castle会员中心</p>
          </div>
          <div class="form">
            <el-form :model="loginForm" status-icon ref="loginForm" class="demo-ruleForm">
              <el-form-item
                prop="phone"
                :rules="[
                  {
                    required: true,
                    message: '请输入登录手机号',
                    trigger: 'blur',
                  },
                  {
                    validator: ftVal.validatePhone,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="loginForm.phone"
                  autocomplete="off"
                  prefix-icon="iconfont pdxshouji"
                  placeholder="请输入登录手机号"
                  :clearable="false"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="smsCode"
                :rules="[
                  { required: true, message: '请输入验证码', trigger: 'blur' },
                ]"
              >
                <el-input
                  type="password"
                  v-model="loginForm.smsCode"
                  autocomplete="off"
                  prefix-icon=" iconfont pdxicon2"
                  placeholder="请输入验证码"
                  show-password
                  :clearable="false"
                ></el-input>
                <span v-if="showSecond" class="code" @click="sendSmsHandler">获取验证码</span>
                <span v-else class="code">{{ second }}s重新发送</span>
              </el-form-item>
              <!-- <div class="loginCheck">
                <el-checkbox v-model="loginForm.checked" @change="changeCheckBox">下次自动登录</el-checkbox>
              </div>-->
              <!-- <div class="other layout-flex">
                <a href="#" @click="changeToWX()">
                  <img src="@/assets/img/login_wx.png" />
                </a>
              </div>-->
              <el-form-item>
                <el-button style="width: 100%" @click="submitForm('loginForm', 1)">登录</el-button>
              </el-form-item>
            </el-form>
            <p class="agree">
              登录即代表同意
              <a href>《华创服务协议》</a>和
               <a href>《隐私保护政策》</a>
            </p>
          </div>
          <div class="top" @click="change">
            <div class="line">
              <p>微信扫码登录</p>
              <img src="@/assets/img/qrcode.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyRight">
      Copyright © 2021 hcses. All Rights Reserved  备案号：<a href="https://beian.miit.gov.cn/">鲁ICP备16033576号</a>增值电信业务经营许可证：鲁B2-20200417
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login.js";
import { sendSms, wxLoginCode, getNewSerialNum } from "@/api/index.js";
import * as ftVal from "@/common/fortressValidator.js";
import { base64decode } from "@/common/index.js";
import { userInfo } from "@/api/index.js";
import { v4 as uuidv4 } from "uuid";
export default {
  data () {
    return {
      hover: false,
      second: 60, //验证码倒计时
      showSecond: true, //是否显示倒计时
      loginForm: {
        phone: "",
        smsCode: "",
        type: 1,
      },
      ftVal: ftVal,
      originUrl: "",
      base64OriginUrl: "", //未解析的跳转url
      toWxCode: process.env.VUE_APP_ROOT_URL_ENV + "wxLoginCode",
      loginFlag: "wx",
      qrcodesrc: "",
      token: localStorage.getItem('token'),
    };
  },
  created () {
    this.originUrl = this.$route.query.originUrl;
    if (this.originUrl && !this.token) { //需要跳转但未登录。需要登录
      this.toWxCode =
        this.toWxCode + "?originUrl=" + this.$route.query.originUrl;
      console.log(this.toWxCode);
      this.base64OriginUrl = this.originUrl;
      this.originUrl = base64decode(this.originUrl);
    }
    else if (this.originUrl && this.token) { //有跳转 并且当前已登录
      this.originUrl = base64decode(this.originUrl);
      //根据当前用户获取新的流水号
      getNewSerialNum().then((resp) => {
        console.log(this.originUrl)
        if (resp.code == 0) {
          window.location.href = this.originUrl + "&serialNum=" + resp.data.serialNum;
        }
      })
      // window.location.href = this.originUrl+"&serialNum="+resp.data.serialNum;
    }

    if (!this.originUrl && this.token) { //没有originUrl 但登录过了 直接进首页
      this.$router.push("/home");
    }
    this.changeToWX();
  },

  components: {},

  computed: {},

  methods: {
    enter () {
      this.$refs.wxQrcode.style.width = '100%'
      setTimeout(() => {
        this.$refs.pic.style.opacity = 1
      }, 300)
    },
    leave () {
      this.$refs.wxQrcode.style.width = '200px'
      this.$refs.pic.style.opacity = 0


    },
    //切换登录方式
    change () {
      if (this.loginFlag == "wx") {
        this.loginFlag = "phone";
      } else {
        this.loginFlag = "wx";
        this.changeToWX();
      }
    },
    //获取验证码
    sendSmsHandler () {
      if (!this.loginForm.phone) {
        this.$message({
          message: "请填写手机号",
          type: "error",
        });
        return;
      } else {
        let reg = /^[1][3-9][0-9]{9}$/;
        if (!reg.test(this.loginForm.phone)) {
          this.$message({
            message: "请填写正确的手机号码",
            type: "error",
          });
          return;
        }
      }
      sendSms({ mobile: this.loginForm.phone, type: this.loginForm.type }).then(
        (resp) => {
          if (resp.code == 0) {
            this.$message({
              type: "success",
              message: "验证码发送成功, 请注意查收!",
            });
            //按钮倒计时
            this.showSecond = false;
            var interval = setInterval(() => {
              let times = --this.second;
              this.second = times < 10 ? "0" + times : times; //小于10秒补 0
            }, 1000);
            setTimeout(() => {
              clearInterval(interval);
              this.second = 60;
              this.showSecond = true;
            }, 60000);
          } else {
            this.$message({
              message: resp.msg,
              type: "error",
            });
          }
        }
      );
    },
    //登录
    submitForm (formName, num) {
      this.loginType = num;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.loginForm).then((resp) => {
            if (resp.code == 0) {
              //保存用户信息
              this.$store.commit("setToken", resp.data.token);
              localStorage.setItem(
                "fortressExpiredTime",
                resp.data.expiredTime
              );
              localStorage.setItem(
                "fortressRefreshToken",
                resp.data.refreshToken
              );
              if (this.originUrl) {
                window.location.href = this.originUrl + "&serialNum=" + resp.data.serialNum;
              } else {
                userInfo().then((resp) => {
                  this.$store.commit("setUserInfo", resp.data);
                  //登录成功后 保存token信息 并跳转到home页
                  this.$router.push({ name: "dashboard" });
                });
              }
            } else {
              this.loginForm.smsCode = "";
              this.$message({
                message: resp.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //切换手机登录
    changeToPhone () {
      this.loginFlag = "phone";
    },
    //切换微信登录
    changeToWX () {
      // let params = {};
      // if (this.originUrl) {
      //   params = {
      //     originUrl: this.originUrl,
      //   };
      // }
      this.loginFlag = "wx";
      let uuid = uuidv4();
      uuid = uuid.replace("-", "");
      console.log(uuid);
      let wxUrl = "http://console.icuapi.com/op/wxLogin";
      if (this.originUrl) {
        wxUrl += "?originUrl=" + this.base64OriginUrl;
      }
      this.$nextTick(() => {
        var obj = new WxLogin({
          self_redirect: false,
          id: "wxQrcode",
          appid: "wx90e8169f8046101a",
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(wxUrl),
          state: uuid,
          style: "black",
          href: "data:text/css;base64,LndycF9jb2Rle3dpZHRoOiAyMDBweDt9LmltcG93ZXJCb3ggLnFyY29kZSB7IHdpZHRoOiAyMDBweDttYXJnaW4tdG9wOiAwO3BhZGRpbmc6IDEwcHg7Ym94LXNpemluZzogYm9yZGVyLWJveDt9LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30uaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTpub25lfQ==",
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.iframeBox {
  background: red;
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
  
  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../assets/img/login_bg.png);
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: text;
    .box {
      align-items: flex-start;
      margin: 0 auto;
      max-width: 860px;
      width: 110%;
      height: 585px;
      position: absolute;
      top: 15%;
      // bottom: 0;
      right: 50%;
      transform: translateX(50%);
      // left: 0;
      background: url(../assets/img/login_inner_bg.png) no-repeat 100% 0 / 100%
        100%;
      box-sizing: border-box;
      .left {
        // padding-left: 50px;
        width: 325px;
        height: 100%;
        position: relative;
        .project-name {
          position: absolute;
          top: 60px;
          left: 60px;
          color: #3a405b;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 1px;
          font-family: '阿里巴巴普惠体', Arial, Helvetica, sans-serif;
          user-select: text;
          img {
            margin-right: 10rpx;
            width: 40px;
            height: 40px;
          }
        }
        .list {
          font-size: 15px;
          color: #1c2e46;
          position: absolute;
          bottom: 50px;
          left: 60px;
          li {
            padding: 8px 0;
            letter-spacing: 1px;
            user-select: text;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .right {
        padding: 120px 45px 20px;
        width: calc(860px - 325px);
        position: relative;
        .name {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 19px;
          color: #3a405b;
          img {
            width: 26px;
            margin-right: 10px;
          }
        }
        .agree {
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
          color: #a1a6bb;
          a {
            color: #0076fe;
          }
        }
        .part {
          margin-bottom: 30px;
          width: 100%;
          justify-content: space-between;

          .form {
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .form .wx-detail {
            display: flex;
            align-items: center;
          }
          .pic {
            height: 200px;
            img {
              height: 100%;
            }
          }
        }
        // .loginCheck {
        //   text-align: center;
        //   &::v-deep .el-checkbox__label {
        //     font-size: 15px;
        //     color: #3a405b;
        //     font-weight: normal;
        //   }
        // }
        .top {
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
          .line {
            display: flex;
            align-items: flex-start;
            img {
              width: 38px;
            }
            p {
              padding: 3px 10px;
              margin-right: 10px;
              color: #738299;
              border: 1px solid #ddd;
              position: relative;
              background: #fff;
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -10px;
                transform: translateY(-50%);
                width: 0px;
                height: 0;
                border-width: 5px;
                border-style: solid dashed dashed dashed; /*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
                border-color: transparent transparent transparent #fff;
              }
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -12px;
                transform: translateY(-50%);
                width: 0px;
                height: 0;
                border-width: 6px;
                border-style: solid dashed dashed dashed; /*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
                border-color: transparent transparent transparent #ddd;
              }
            }
          }
        }
        .other {
          margin-bottom: 40px;
          color: #999;
          font-size: 14px;
          cursor: pointer;
          a {
            img {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }
          }
        }
      }
      // 手机登录
      .phone {
        padding: 120px 70px 20px;
        .welcome {
          margin-bottom: 30px;
          color: #3a405b;
          h3 {
            font-size: 28px;
            margin-bottom: 10px;
            letter-spacing: 2px;
          }
          p {
            font-size: 16px;
            letter-spacing: 1px;
            font-weight: bold;
          }
        }
        .form {
          &::v-deep .el-form-item {
            margin-bottom: 30px;
          }
          & ::v-deep .el-input__inner {
            height: 50px;
            line-height: 50px;
            border: 1px solid #d9d9d9;
            background: #fff;
            border-radius: 50px !important;
          }
          &::v-deep .el-button {
            height: 50px;
            color: #fff;
            background: #0076fe;
            border: none;
            border-radius: 50px;
          }
          & ::v-deep .el-button:hover,
          &::v-deep .el-button:focus {
            color: #fff !important;
            background: #0076fe !important;
            border: none !important;
            opacity: 0.8;
          }
          & ::v-deep .el-input__prefix {
            height: 100%;
            width: 25px;
            text-align: center;
          }
          &::v-deep .el-form-item .el-form-item__content {
            display: flex;
            .code {
              margin-left: 10px;
              text-align: center;
              width: 160px;
              color: #b9bed1;
              background: #f1f7ff;
              border-radius: 50px;
              cursor: pointer;
              line-height: 50px;
            }
          }
        }
        .loginCheck {
          margin: -10px 0 20px;
          text-align: left;
        }
        .agree {
          margin-top: -15px;
          text-align: center;
          font-size: 14px;
          color: #a1a6bb;
          a {
            color: #0076fe;
          }
        }
      }
    }
  }
  .copyRight{
    width: 100%;
    position: fixed;
    bottom: 20px;
    left:0;
    text-align: center;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
    user-select: text;
    a{
      color: #fff;
    }
  }
}
</style>
